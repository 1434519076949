import React from "react";
import "./App.css";
import Footer from "./components/Footer";
import Hadder from "./components/Hadder";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Services from "./pages/Services";
import Contectus from "./pages/Contectus";
import Galle from "./pages/gallery";
import Servicedetail from "./pages/service-detail";
// import Servicebeauty from "./components/searvicebeauty";
// import Button from 'react-bootstrap/Button';
// import Academy from "./pages/Acedemy";

import Servicedetail1 from "./pages/Service-detail1";
import Servicedata from "./components/Servicedata";
import Servicedata1 from "./components/Servicedata1";
import ERROR from "./components/ERROR"
function App() {
  return (
    <div className="App">
      <BrowserRouter>
     
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/top-salon-andheri" element={<Aboutus />}></Route>
          <Route path="/salon-in-andheri" element={<Services />}></Route>
          <Route path="/best-unisex-salon-andheri" element={<Galle />}></Route>
          <Route path="/top-unisex-salon-andheri" element={<Contectus/>}></Route>
         
        
          <Route path="/ServicedetSS" element={<Servicedetail/>}></Route>
          <Route path="/ServicedetSS1" element={<Servicedetail1/>}></Route>
          {/* <Route path="/Cafe" element={<Cafe/>}></Route>
          <Route path="/beauty-academy-andheri" element={<Academy/>}></Route> */}
          <Route path="/*" element={<ERROR/>}></Route>
          {
            Servicedata.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Servicedetail />}
                  />
                </>
              )
            })
          }
           {
            Servicedata1.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Servicedetail1/>}
                  />
                </>
              )
            })
          }
        
        </Routes>
      </BrowserRouter>
   
    </div>
  );
}

export default App;
