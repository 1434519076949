import React from 'react'
import "../css/ace.css"
import { Link } from 'react-router-dom'
// import ac1 from "../assets/ac/ac1.avif"
// import ac2 from "../assets/ac/ac2.avif"
// import ac3 from "../assets/ac/ac3.webp"
// import ac4 from "../assets/ac/ac4.avif"
// import ac5 from "../assets/ac/ac5.avif"
// import ac6 from "../assets/ac/ac6.avif"
// import ac7 from "../assets/ac/ac7.avif"
import Servicedata1 from '../components/Servicedata1'
const Acce = () => {
  return (
    <div>
 
 <>
  
  
  <div className="row">
  {Servicedata1.map((ele) => {
                        const { ServiceName, slug, ServiceShort,  ServiceImage} = ele;
                        return (
    <div className="col-md-3 marg20">
       <Link to={`/ServicedetSS1?id=${slug}`}></Link>
    <div className="card" style={{backgroundImage:`url(${ ServiceImage})`}}>
    <div className="card-content">
      <h2 className="card-title">{ServiceName}</h2>
     
      <button href="" className="btn" >
      <Link to={`${slug}`}>Learn More</Link>
      </button>
      {/* <a href="" className="btn">
      <Link to={`/ServicedetSS1?id=${id}`}>Learn More</Link>
        
      </a> */}
    </div>
  </div>
    </div>
     );
    })}

   
  </div>
  
 
</>

    </div>
  )
}

export default Acce
