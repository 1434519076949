import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {photos} from "../components/photos";
import { MetaTags } from 'react-meta-tags';
import Hadder from "../components/Hadder";
import Footer from "../components/Footer";

const Galle = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };

  return (
    <div>
      <Hadder></Hadder>
       <MetaTags>
      <title>Best Unisex Salon In Andheri | Malhana salon Salon</title>
        <meta title="Best Unisex Salon In Andheri | Malhana salon Salon" />
        <meta
          name="description"
          content="Indulge in luxury and style at the Best Unisex Salon In Andheri. Our expert stylists offer a wide range of beauty services to enhance your look."
        />
        <meta
          name="keywords"
          content="best salon in andheri,salon in andheri,skin care salon in andheri,beauty treatment salon in adheri,beauty academy in Andheri,top salon in andheri,skincare salon in andheri,best skin care salon in andheri,hair care salon in andheri,nail salon in andheri,makeup studio in andheri,nail art service in andheri,Eyelash Extension Services in Andheri,top cosmetologist in andheri,Manicure and pedicure in andheri,Best Hair Academy in mumbai,best skin academy in mumbai,best makeup academy in  andheri,Best nailart Academy in mumbai,best Eye Lashes  academy in mumbai,best  Cosmetology academy in mumbai,best Manicure and pedicure academy in mumbai,best unisex salon in andheri,top unisex salon in andheri"
        />
        <link rel="canonical" href="http://malhanasaloon.in/best-unisex-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Unisex Salon In Andheri | Malhana salon Salon" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
          </MetaTags>
             <section className="page_header d-flex align-items-center">
  <div className="container">
    <div
      className="section_heading text-center mb-40 wow fadeInUp"
      data-wow-delay="300ms"
      style={{
        visibility: "visible",
        animationDelay: "300ms",
        animationName: "fadeInUp"
      }}
    >
      
      <h1> Best Unisex Salon In Andheri </h1>
      <div className="heading-line" />
    </div>
  </div>
</section>
<div className="container"style={{marginTop:"20px" ,marginBottom:"20px"}}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      </div>
      <Footer></Footer>
    </div>
  );
      
 
 
}

export default Galle
