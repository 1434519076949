import g1 from "../assets/serv/serv1.jpg";
import g2 from "../assets/serv/serv2.jpg";
import g3 from "../assets/serv/serv3.jpg";
import g4 from "../assets/serv/serv4.jpg";
import g5 from "../assets/serv/serv5.jpg";
import g6 from "../assets/serv/serv6.jpg";
import g7 from "../assets/serv/serv7.jpg";
import g8 from "../assets/home/home3.jpeg";
import g9 from "../assets/home/Hairdressing.webp";

export const photos = [
  {
    src:g1,
    width: 4,
    height: 3
  },
  {
    src: g2,
    width: 1,
    height: 1
  },
  {
    src:g3,
    width: 3,
    height: 4
  },
  {
    src:g4,
    width: 3,
    height: 4
  },
  {
    src: g5,
    width: 3,
    height: 4
  },
  {
    src:g6,
    width: 4,
    height: 3
  },
  {
    src: g7,
    width: 3,
    height: 4
  },
  {
    src: g8,
    width: 4,
    height: 3
  },
  {
    src:g9,
    width: 4,
    height: 3
  }
];
