import React from 'react'
import { Link } from "react-router-dom";
import Hadder from './Hadder';
import Footer from './Footer';
import "../css/Error.css"
const ERROR = () => {
    return (
        <>
        <Hadder></Hadder>
            <div className='Errorpage'>
                <div class='error'>
                    <h1 class='code' style={{color:"#eb5b9a"}}>404</h1>
                    <h2 class='desc' style={{marginTop:"60px"}}>Ops... There's something wrong.</h2>
                    <Link to="/" style={{color:"white"}}> 
                        ... Back to previous page
                    </Link>
                </div>
            </div>
<Footer></Footer>

        </>
    )
}

export default ERROR
