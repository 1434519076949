import React from 'react'
import one from "../assets/MALHANA PROFESSIONAL UNISEX SALON (1).png"
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <section className="widget_section padding">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-6 sm-padding">
        <div className="footer_widget">
        <Link to="/">
          <img className="mb-15" src={one} style={{width:"130px"}} alt="Malhana salon logo" />
          </Link>
          <p>
          Our salon is created for men who appreciate premium quality, time, and a flawless look.
          </p>
          <ul className="widget_social">
            <li>
              <a href="#">
                <i className="social_facebook" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="social_twitter" />
              </a>
            </li>
          
          </ul>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 sm-padding">
        <div className="footer_widget">
          <h3>Address</h3>
          <p>
          
          Shop # D3, Society #-44, Old Mhada, Rd, Behind Versova Telephone Exchange, SV Patel Nagar, Andheri -W
          
         
         </p>
         <h3>Email</h3>
          <p>
          priyankaupadhyay283@gmail.com<br />
          <h3>Contact</h3>
          6260580775
          <br />
            
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 sm-padding">
        <div className="footer_widget">
          <h3>Opening Hours</h3>
          <ul className="opening_time">
            <li>11:00am - 10:30pm</li>
            <li>Monday – Sunday</li>
            
          </ul>
        </div>
      </div>
      
    </div>
  </div>
</section>
<footer className="footer_section">
  <div className="container">
    <div className="row">
      <div className="col-md-12 xs-padding">
        <div className="copyright">
        <div className="Copy-Right">
  <div className="Contact-Us-Copy">
    © Copyright
    <strong>
      <span style={{ color: "#eb5b9a" }}> Malhana Unisex Salon </span>
    </strong>
    . All Rights Reserved
  </div>
  <div className="credits">
    Designed by
    <a href="https://skdm.in/" style={{ color: "#eb5b9a" }}>
      Shree Krishna Digital Marketing
    </a>
  </div>
</div>
        </div>
      </div>
      
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
