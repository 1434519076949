import "../css/testimonial.css";
import p from "../assets/p.jpg";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
const Testimonial = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <div>
      <>
        <section className="service_section bg-grey padding">
          <div className="container">
          <div
            className="section_heading text-center mb-40 wow fadeInUp"
            data-wow-delay="300ms"
            style={{
              visibility: "visible",
              animationDelay: "300ms",
              animationName: "fadeInUp",
            }}
          >
            <h3>Good words&amp; </h3>
            <h2>Testimonials</h2>
            <div className="heading-line" />
          </div>
            <div className="row">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="myTestimonal"
              >
                <SwiperSlide>
                  <div>
                    <div className="card2" style={{ padding: "20px" , margin:"20px" }}>
                      

                      <p>
                      I recently visited the beauty parlour and had an amazing experience! From the moment I walked in, the staff were warm and welcoming, and I immediately felt at ease. The facilities were sparkling clean and well-maintained, which added to the overall ambiance. 
                      </p>
                      <h4 className="">Anindya Bag</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="card2" style={{  padding: "20px" , margin:"20px" }}>
                   

                      <p>
                      Great customer care by the owner, miss malhana. Professional services and good products used. Staff go the extra length to make sure you're comfortable every step of the way   </p>
                      <h4 className="">Nigel nizar</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="card2" style={{  padding: "20px" , margin:"20px" }}>
                     

                      <p>
                      Exceptional service at Malhana Unisex Salon! The attention to detail and friendly staff made my visit memorable. I left feeling rejuvenated and looking fabulous.
                      </p>
                      <h4 className="">Preeti Singh</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="card2" style={{ padding: "20px" , margin:"20px"  }}>
                      

                      <p>
                      A small establishment started few months ago in MHADA by a lady having 10 plus years of experience.

This experience is visible during the process of facial.
                      </p>
                      <h4 className="">Milind Chavan</h4>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Testimonial;
