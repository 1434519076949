
import ser1 from "../assets/icons/s1.png"
import ser2 from "../assets/icons/s2.png"
import ser3 from "../assets/icons/s3.png"
import ser4 from "../assets/icons/s4.png"
import ser5 from "../assets/icons/s5.png"
import ser6 from "../assets/icons/s6.png"
import ser7 from "../assets/icons/s7.png"
 import serv1 from "../assets/serv/serv1.jpg"
 import serv2 from "../assets/serv/serv2.jpg"
 import ace2 from "../assets/ac/ace2.jpeg"
 import ace4 from "../assets/ac/ace4.jpeg"
 import serv3 from "../assets/serv/serv3.jpg"
 import serv4 from "../assets/serv/serv4.jpg"
 import serv5 from "../assets/serv/serv5.jpg"
 import serv6 from "../assets/serv/serv6.jpg"
 import serv7 from "../assets/serv/serv7.jpg"
 import ac1 from "../assets/ac/ac1.avif"
import ac2 from "../assets/ac/ac2.avif"
import ac3 from "../assets/ac/ac3.webp"
import ac4 from "../assets/ac/ac4.avif"
import ac5 from "../assets/ac/ac5.avif"
import ac6 from "../assets/ac/ac6.avif"
import ac7 from "../assets/ac/ac7.avif"

const Servicedata1 = [
    {
        id: 0,
        slug:"/best-skin-mumbai",
        service:"Best Skin in Mumbai",
        title:"Best Skin in Mumbai | Malhana Salon",
        coninical:"http://malhanasaloon.in/best-skin-mumbai",
        desription:" Looking for the Best Skin In Mumbai? Join our s for comprehensive beauty education, and learn the art of achieving flawless skin.",
        ServiceImage: ac1,
        ServiceImage1: serv1,
        ServiceName: " Skin  ",
        ServiceShort: "Launch your career as a Skin ",
        servicelong:"Discover a range of luxurious skin care treatments at our Mumbai salon, designed to pamper and revitalize your skin. From revitalizing facials to advanced skincare procedures, our expert professionals use the best skin care products and techniques to ensure your skin receives the care it deserves. At our salon, we believe that everyone deserves radiant and healthy skin, and that's why we've become the go-to destination for the best skin care in Mumbai. Immerse yourself in the ultimate skin care experience, where our passion for excellence meets your desire for beautiful, glowing skin. Experience the transformative touch of the best skin care in Mumbai at our salon, where your skin's well-being is our top priority.",
        
       
    },
    {
      id: 1, 
      slug:"/best-hair-mumbai",
      service:"Best Hair in Mumbai",
      title:"Best Hair in Mumbai | Malhana Salon",
      coninical:"http://malhanasaloon.in/best-hair-mumbai",
      desription:" Discover excellence in hair education at the Best Hair In Mumbai. Our expert trainers and comprehensive s will help you unleash your creativity ", 
      ServiceImage: ace2,
      ServiceImage1: serv2,
      ServiceName: " Hair ",
      ServiceShort: "Launch your career as a Hair",
      servicelong:"Hair tends to form an integral part of your overall beauty. However, in today’s busy and packed lifestyle, we are often left with no time to take care of our skin let alone hair. Consequences are frizzy and dry hair, problems such as hair loss, dry and sensitive scalp and dandruff, to name a few. Hence, pampering hair with nourishing hair treatments and spas at regular intervals becomes crucial. Irrespective of which hair problem you are suffering from, our range of hair care and treatment options can help you get rid of it. Good hair can make it and bad hair can break it. A good haircut helps shape your face and keeps you feeling and looking beautiful. Now get your hair washed, cut and styled at bang's unisex salon for a new look, every time",
      
        
    },
    {
      id: 2, 
      slug:"/best-makeup-artist-andheri",
      service:"Best Makeup In Andheri",
      title:"Best Makeup Artist In Andheri | Malhana Salon",
      coninical:"http://malhanasaloon.in/best-makeup-andheri",
      desription:" Elevate your beauty skills at the Best Makeup In Andheri. Learn from experts, unleash your creativity and embark on a journey .",
      ServiceImage: ac3,
      ServiceImage1: serv3,
      ServiceName: " Makeup ",
      ServiceShort: "Launch your career as a Makeup ",
      servicelong:"Our expert team of professionals is dedicated to providing you with the best makeup services in Andheri, ensuring you leave our salon looking and feeling your absolute best. At Malhana, we understand the importance of flawless makeup that enhances your natural beauty, and that's why we take pride in being the go-to destination for the best makeup in Andheri.Indulge in our luxurious makeup services that are tailored to suit your individual style and preferences. Whether you're preparing for a special occasion, a glamorous night out, or just want to treat yourself, our skilled makeup artists at Malhana Salon will use their expertise to create a stunning and long-lasting look for you. With our commitment to excellence, you can trust us to deliver the best makeup services in Andheri, leaving you with a radiant and confident glow every time you step out of our salon. Experience the magic of Malhana Salon, where we redefine beauty with the best makeu-artist in Andheri!",
    },
    {
      id: 3, 
      slug:"/best-nailart-mumbai",
      service:"Best Nailart In Mumbai",
      title:"Best Nailart In Mumbai | Malhana Salon",
      coninical:"http://malhanasaloon.in/best-nailart-mumbai",
      desription:"Unleash your artistic potential at the Best Nailart In Mumbai. Join us to learn and master the art of nail design and decoration.",
      ServiceImage: ace4,
      ServiceImage1: serv4,
      ServiceName: "Nail Art  ",
      ServiceShort: "Launch your career as a nail artist",
      servicelong:"We believe that we have what our clients need: professional nail technicians, clean and hygienic tools, a wide range of colour selections; with a welcoming and relaxing atmosphere. We are great nail technicians and we try to hire people that meet our standards. Our nail techs are licensed, well trained, experienced, talented and have a great passion for their work. Cleanliness is a detailed focus in our salon. We follow proper procedures and use hospital grade disinfectant to make sure our tools are clean and safe to use. The tools are cleaned, disinfected, dried and packed individually for each client and kept in the sterilizer machine until being used. We dispose of single-use tools such as the buffer and the wood sticks to improve our quality and hygienic practices. At Thai Nails, we only use the best and the most popular nail polish available, with a wide range of colours from nail lacquer, shellac/gel polish and dipping powder colours.",
    },
    {
      id: 4,  
      slug:"/best-waxing-mumbai",
      service:"Best Waxing Mumbai",
      title:"Best Waxing  In Mumbai | Malhana Salon",
      coninical:"http://malhanasaloon.in/best-waxing-mumbai",
           ServiceImage:serv5,
      ServiceImage1: serv5,
      ServiceName: "Waxing ",
     
      servicelong:" Our skilled estheticians use only the finest waxing products from renowned brands to ensure a gentle and effective hair removal experience. Indulge in the luxury of the best waxing in Mumbai as we cater to your specific preferences and make your waxing session as comfortable as possible.At our salon, we prioritize your satisfaction, and that's why we've chosen the best waxing products available in the market. Our expert technicians use these premium brands to provide a superior waxing experience, leaving your skin silky smooth and free from unwanted hair. With our commitment to excellence, you can trust us for the best waxing in Mumbai, where every session is a blend of precision, care, and the use of top-quality products. Pamper yourself with the best waxing in Mumbai at our salon, where we make your comfort and smooth skin our top priorities.",
       
    },
    {
      id: 5, 
      slug:"/best-body-polishing-mumbai",
      service:"Best Body polishing  In Mumbai",
      title:"Best Body Massage In Mumbai |Malhana Salon",
      coninical:"http://malhanasaloon.in/best-body-massage-mumbai",
        ServiceImage: ac6,
      ServiceImage1: serv6,
      ServiceName: "Body Polishing",
          servicelong:"Our commitment to excellence is reflected in the premium products we use for body polishing, ensuring that you receive nothing short of the best at our Mumbai salon. Allow our skilled hands to exfoliate, nourish, and rejuvenate your skin, making your body polishing experience in Mumbai a truly transformative one.Indulge in the best body polishing in Mumbai at our salon, where every session is crafted to provide you with a revitalizing escape. From gentle exfoliation to nourishing treatments, our skilled therapists are dedicated to ensuring that you leave our salon feeling pampered, refreshed, and confident in your radiant skin. Discover the art of the best body polishing in Mumbai with us, where luxury and skincare seamlessly come together for an unforgettable experience."       
    },
    {
      id: 6,  
      slug:"/best-manicure-pedicure-mumbai",
      service:"Best Manicure  Pedicure In Mumbai",
      title:"Best Manicure  Pedicure In Mumbai | Malhana Salon",
      coninical:"http://malhanasaloon.in/best-manicure-pedicure-mumbai",
      desription:"Looking for the Best Manicure and Pedicure In Mumbai? Explore our top-rated training center, offering expert s for nail care and styling.",
      ServiceImage: ac7,
      ServiceImage1: serv7,
      ServiceName: "Manicure  ",
      ServiceShort: " Launch your career as a Manicure/pedicure ",
      servicelong:"The best manicure & pedicure in Mumbai, focusing on precision and detail. Our commitment to excellence is reflected in the premium nail care products we use, ensuring a flawless finish and long-lasting results. From meticulous nail shaping to indulgent massages, our salon is your haven for the best manicure & pedicure in Mumbai. Immerse yourself in the relaxing ambiance of our salon as you enjoy the best manicure & pedicure services in Mumbai. Whether you're seeking a classic look or a trendy nail art design, our skilled professionals are dedicated to making your experience truly exceptional. Elevate your nail care routine with the best manicure & pedicure in Mumbai at our salon, where beauty and relaxation converge for a pampering session like no other.",
       
    },
    
  
  
  
]

export default Servicedata1;